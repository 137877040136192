import { useState } from "react";
import { useAPI } from "../../context";
import { db, storage } from "../../firebase/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "react-toastify";
import TabAdmin from "../tabAdmin";
import { v4 as uuidv4 } from 'uuid';
import Members from "../members";
import Tab from "../../components/tab";
import Title from "../title";
import { EntryType, DashboardTabsType } from "../../context/@types.context";

interface AdminStateType {
  noticeBoard: string;
  specifications: string;
  schedule: string;
}

const Renovation = () => {
  const { remont, setBase64Data, base64Data, selectedFile, setRemont, dataUser } = useAPI();
  
  const [admin, setAdmin] = useState<AdminStateType>({
    noticeBoard: "",
    specifications: "",
    schedule: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectTab, setSelectTab] = useState(remont.constructionZone ?? []);
  const [selectName, setSelectName] = useState("constructionZone");
  const [selectedRange, setSelectedRange] = useState<[Dayjs, Dayjs] | null>(null);

  const base64ToBlob = (base64: string, mime: string): Blob => {
    const byteChars = atob(base64.split(",")[1]);
    const byteArrays = [];
    for (let offset = 0; offset < byteChars.length; offset += 512) {
      const slice = byteChars.slice(offset, offset + 512);
      byteArrays.push(
        new Uint8Array(slice.split("").map((char) => char.charCodeAt(0)))
      );
    }
    return new Blob(byteArrays, { type: mime });
  };

  const convertDateToTimestamp = (date: Dayjs): number => {
    return date.unix();
  };

  const uploadData = async (
    type: "noticeBoard" | "specifications" | "schedule",
    isPrivate: boolean
  ): Promise<void> => {
    const data = admin[type];

    if (remont.completed) {
      toast.info("Eksploatacja / Remont został zakończony", { autoClose: 3000 });
      return;
    }

    if (data.length === 0) {
      toast.error("Wprowadź tekst", { autoClose: 3000 });
      return;
    }

    if (type === "schedule") {
      const timestamp = dayjs().unix();
      const uid = localStorage.getItem("uid");

      if (!selectedRange) {
        toast.error("Podaj przedział czasowy", { autoClose: 3000 });
        return;
      }

      const startTimestamp = convertDateToTimestamp(selectedRange[0]);
      const endTimestamp = convertDateToTimestamp(selectedRange[1]);
      const id = uuidv4();

      const dataObject = {
        id: id,
        description: data,
        createdTime: timestamp,
        startTimestamp: startTimestamp,
        endTimestamp: endTimestamp,
        isPrivate: isPrivate,
        uid: uid
      };

      if (!remont.key) {
        throw new Error("Remont key is undefined");
      }

      try {
        const docRef = doc(db, "remonts", remont.key);
        await updateDoc(docRef, { [type]: arrayUnion(dataObject) });

        setRemont((prevState) => ({
          ...prevState,
          [type]: [
            ...(prevState[type] ?? []),
            dataObject,
          ]
        }));

        setSelectTab((prevState) => [
          ...(prevState ?? []),
          dataObject
        ] as EntryType[]);

        toast.success("Dodano harmonogram", { autoClose: 3000 });
      } catch {
        toast.error("Coś poszło nie tak", { autoClose: 3000 });
      } finally {
        setSelectedRange(null);
        setLoading(false);
        setAdmin({
          noticeBoard: "",
          specifications: "",
          schedule: "",
        })
      }
      return;
    }

    setLoading(true);
    const nameImg = selectedFile ? selectedFile.name : "default.jpg";
    const timestamp = dayjs().unix();
    const uid = localStorage.getItem("uid");

    try {
      let fileURL: string | null = null;
      if (base64Data) {
        const mime = "image/jpeg";
        const blob = base64ToBlob(base64Data, mime);
        const storageRef = ref(storage, `images/${remont.key}/${nameImg}_${timestamp}`);
        const snapshot = await uploadBytes(storageRef, blob);
        fileURL = await getDownloadURL(snapshot.ref);
      }

      const id = uuidv4();

      const dataObject = {
        id: id,
        link: fileURL ?? null,
        description: data,
        createdTime: timestamp,
        uid: uid,
        isPrivate: isPrivate
      };

      if (!remont.key) {
        throw new Error("Remont key is undefined");
      }

      const docRef = doc(db, "remonts", remont.key);
      await updateDoc(docRef, { [type]: arrayUnion(dataObject) });

      setRemont((prevState) => ({
        ...prevState,
        [type]: [
          ...(prevState[type] ?? []),
          dataObject,
        ]
      }));

      setSelectTab((prevState) => [
        ...(prevState ?? []),
        dataObject,
      ] as EntryType[]);

      toast.success("Dodano ogłoszenie", { autoClose: 3000 });
    } catch (error) {
      toast.error("Coś poszło nie tak", { autoClose: 3000 });
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
      setBase64Data(null);
      setAdmin({
        noticeBoard: "",
        specifications: "",
        schedule: "",
      });
    }
  };

  const getUploadFunction = (): (item: boolean) => Promise<void> => {
    switch (selectName) {
      case "noticeBoard":
        return async (isPrivate: boolean) => uploadData("noticeBoard", isPrivate);
      case "specifications":
        return async (isPrivate: boolean) => uploadData("specifications", isPrivate);
      case "schedule":
        return async (isPrivate: boolean) => uploadData("schedule", isPrivate);
      default:
        return async () => {
          console.warn("No matching upload function found.");
        };
    }
  };

  const choiceTab = (tab: EntryType[], name: DashboardTabsType) => {
    setAdmin({
      noticeBoard: "",
      specifications: "",
      schedule: "",
    });
    setSelectTab(tab);
    setSelectName(name);
  };

  return (
    <div className="renovation">
      <>
        <Tab {...{ selectName, choiceTab }} />
        <Title />
        <p className="renovation__admin">prowadzący: {dataUser.name}</p>

        <TabAdmin
          tab={selectTab}
          setTab={setSelectTab}
          name={selectName}
          uploadFunction={getUploadFunction()}
          loading={loading}
          admin={admin}
          setAdmin={setAdmin}
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
        />
        <Members />
      </>
    </div>
  );
};

export default Renovation;
