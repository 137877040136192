import Modal from "react-modal";
import { useAPI } from "../../context";
import { useState } from "react";
import { customStyles } from "../../utils/customStyles";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Spinner from "../../common/spinner";
import { toast } from "react-toastify";
import ModalKeys from "../modalKeys";

interface ModalEditRepairType {
  isModal: boolean;
  indexRemont: number;
  closeModal: () => void;
}

const ModalEditRepair = ({ indexRemont, isModal, closeModal }: ModalEditRepairType) => {
  const [error, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setisOpenModal] = useState(false);

  const { setRemont, remont, setList, list } = useAPI();
  const { name, description } = remont;

  Modal.setAppElement("#root");

  const employeeKeys = () => {
    setisOpenModal(true);
  }

  const closeModalKeys = () => {
    setisOpenModal(false);
  }

  const saveRemont = async () => {
    setIsLoading(true);

    if (!remont.key) {
      throw new Error("Remont key is undefined");
    }

    try {
      const docRef = doc(db, "remonts", remont.key);
      await updateDoc(docRef, {
        name: name,
        description: description,
      });

      toast.success("Dokument został zaktualizowany", { autoClose: 3000 });

      setList((prevItems) => {
        const updatedItems = prevItems.map((item, index) =>
          index === indexRemont ? { ...item, description: description || '', name: name || '' } : item
        );
        return updatedItems;
      });

    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const removeRemont = async () => {
    setIsLoading(true);

    if (!remont.key) {
      throw new Error("Remont key is undefined");
    }

    try {
      const docRef = doc(db, "remonts", remont.key);
      await deleteDoc(docRef);

      setList((prevItems) => {
        const updatedItems = prevItems.filter(
          (item) => item.key !== remont.key
        );
        return updatedItems;
      });

      toast.success("Remont został usunięty", { autoClose: 3000 });
    } catch (error) {
      setIsError(true);
      console.error("Error removing remont: ", error);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const completeRemont = async () => {
    setIsLoading(true);

    if (!remont.key) {
      throw new Error("Remont key is undefined");
    }

    try {
      const docRef = doc(db, "remonts", remont.key);
      await updateDoc(docRef, {
        completed: true,
      });

      toast.success("Dokument został zaktualizowany", { autoClose: 3000 });

      setList((prevItems) => {
        const updatedItems = prevItems.map((item, index) =>
          index === indexRemont ? { ...item, completed: true } : item
        );

        return updatedItems.sort((a, b) =>
          a.completed && !b.completed ? -1 : 0
        );
      });

    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = evt.target.value;
    setRemont({ ...remont, [evt.target.name]: value });
  };

  return (
    <Modal isOpen={isModal} style={customStyles} onRequestClose={closeModal}>
      <ModalKeys {...{isOpenModal, closeModalKeys}}/>
      <div className="modalEditRepair">
        {!error ? (
          isLoading ? (
            <Spinner />
          ) : (
            <>
              <div className="modalEditRepair__box">
                <p className="modalEditRepair__text">Klucz</p>
                {list[indexRemont] === undefined ? null : (
                  <input
                    maxLength={50}
                    className="modalEditRepair__value"
                    value={list[indexRemont].key}
                    type="text"
                    disabled={true}
                  />
                )}
              </div>
              <p className="modalEditRepair__text">Edycja wpisu</p>
              <form
                onSubmit={(event) => event.preventDefault()}
              >
                <input
                  maxLength={50}
                  className="modalEditRepair__item"
                  value={name}
                  name="name"
                  onChange={(e) => handleChange(e)}
                />
                <textarea
                  maxLength={500}
                  className="modalEditRepair__item modalEditRepair__item--area"
                  value={description}
                  name="description"
                  onChange={(e) => handleChange(e)}
                />

                {remont.completed ? null : (
                  <button
                    className="modalEditRepair__item modalEditRepair__item--submit"
                    onClick={saveRemont}
                  >
                    Zapisz remont
                  </button>
                )}
                {remont.completed ? null : (
                  <button
                    className="modalEditRepair__item modalEditRepair__item--submitCompleted"
                    onClick={completeRemont}
                  >
                    Zakończ remont
                  </button>
                )}
                {remont.completed ? null : (
                  <button
                    className="modalEditRepair__item modalEditRepair__item--submitGenerateKey"
                    onClick={employeeKeys}
                  >
                    Klucze pracownicze
                  </button>
                )}
                <button
                  className="modalEditRepair__item modalEditRepair__item--submitRemove"
                  onClick={removeRemont}
                >
                  Usuń remont
                </button>
              </form>
            </>
          )
        ) : (
          <p className="modalEditRepair__text">Coś poszło nie tak spróbuj póżniej</p>
        )}
      </div>
    </Modal>
  );
};

export default ModalEditRepair;
