import { useAPI } from "../../context";
import { EntryType, DashboardTabsType } from "../../context/@types.context";

interface PropsType {
  selectName: string;
  choiceTab: (tab: EntryType[], name: DashboardTabsType) => void;
}

const Tab = ({ selectName, choiceTab }: PropsType) => {
  const { remont } = useAPI();

  const tabs: { name: DashboardTabsType; label: string }[] = [
    { name: "constructionZone", label: "Eksploatacja / Remont" },
    { name: "noticeBoard", label: "Tablica ogłoszeń" },
    { name: "schedule", label: "Harmonogram" },
    { name: "specifications", label: "Specyfikacja / Dokumenty" },
  ];

  return (
    <div className="tab">
      {tabs.map((tab) => (
        <button
          key={tab.name}
          className={selectName === tab.name ? "tab__buttonTabActive" : "tab__buttonTab"}
          onClick={() => choiceTab(remont[tab.name] ?? [], tab.name)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default Tab;