import { useState } from "react";
import { useAPI } from "../../context";
import ModalMembers from "../../modals/modalMembers";

const Members = () => {

  const { remont } = useAPI();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const closeModal = () => {
    setIsOpenModal(false);
  }

  const openModal = () => {
    setIsOpenModal(true);
  }

  return (
    <footer className="members">
      <ModalMembers isOpenModal={isOpenModal} closeModal={closeModal}/>
      <button onClick={openModal} className="members__box">
        <p className="members__text">Pracownicy</p>
        <p className="members__text">{remont?.members?.length}</p>
      </button>
    </footer>
  );
};

export default Members;
