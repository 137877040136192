import Modal from "react-modal";
import { customStyles } from "../../utils/customStyles";
import { useAPI } from "../../context";
import { db } from "../../firebase/firebase";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import Loader from "../../common/loader";
import { generateKey } from "../../utils/generateKey";

interface ModalKeysType {
  isOpenModal: boolean;
  closeModalKeys: () => void;
}

const ModalKeys = ({ isOpenModal, closeModalKeys }: ModalKeysType) => {
  const { setRemont, remont, setList } = useAPI();
  const [isLoading, setIsLoading] = useState(false);

  const generate = async () => {
    if (isLoading) {
      return;
    }

    if (remont?.employeeKeys?.length === 30) {
      return;
    }

    setIsLoading(true);

    if (!remont.key) {
      throw new Error("Remont key is undefined");
    }

    try {
      const remontDocRef = doc(db, "remonts", remont.key);
      const key = generateKey();

      const obj = { isActive: false, key: key };

      await updateDoc(remontDocRef, {
        employeeKeys: arrayUnion(obj),
      });

      setRemont((prevState) => ({
        ...prevState,
        employeeKeys: [...(prevState.employeeKeys || []), obj],
      }));

      setList((prevState) => 
        prevState.map((item) => {
          if (item.key === remont.key) {
            return {
              ...item,
              employeeKeys: [...(item.employeeKeys || []), obj], // Handle undefined case
            };
          }
          return item;
        })
      );

    } catch (error) {
      console.error("Error adding member: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={isOpenModal}
      style={customStyles}
      onRequestClose={closeModalKeys}
    >
      <div className="modalKeys">
        <button
          className="modalKeys__button"
          onClick={generate}
          disabled={isLoading || remont?.employeeKeys?.length === 30}
        >
          {!isLoading ? "Wygeneruj klucz" : <Loader />}
        </button>

        <div className="modalKeys__keys">
          <table className="modalKeys__table">
            <thead className="modalKeys__thead">
              <tr>
                <th></th>
                <th>Klucze pracownicze</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {remont?.employeeKeys?.map((key, index) => (
                <tr key={index}>
                  <td className="modalKeys__td modalKeys__index">
                    {index + 1}
                  </td>
                  <td className="modalKeys__td modalKeys__key">{key.key}</td>
                  <td className={`modalKeys__td modalKeys__status ${key.isActive ? "modalKeys__status-active" : ""}`}>
                    {key.isActive ? "Aktywny" : "Nieaktywny"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default ModalKeys;
