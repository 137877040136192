import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../common/spinner";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [isVerification, setIsVerification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(event.target.value);
    setIsVerification(emailRegex.test(event.target.value));
  };

  const handleResetPassword = async () => {
    setIsLoading(true);

    if (isVerification) {
      try {
        await sendPasswordResetEmail(auth, email);
        toast.success(
          "Link do resetowania hasła został wysłany na podany adres email.",
          {
            autoClose: 3000,
          }
        );
        setIsSuccess(true);
      } catch (error) {
        toast.error(
          "Wystąpił błąd podczas wysyłania linku do resetowania hasła.",
          {
            autoClose: 3000,
          }
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="resetPassword">
      <ToastContainer theme="colored" progressClassName="custom-progress-bar" />
      <h1 className="resetPassword__title">Reset hasła</h1>
      <div className="resetPassword__wrapper">
        {isLoading ? <Spinner /> : null}
        {isSuccess ? (
          <Link className="resetPassword__link" to="/login">
            Wróć na stronę logowania
          </Link>
        ) : (
          <>
            <p className="resetPassword__text">
              Wprowadź poniżej adres email, aby zresetować hasło
            </p>
            <input
              maxLength={50}
              className="resetPassword__value"
              type="text"
              placeholder="Adres email"
              value={email}
              onChange={handleInputChange}
            />
            <button
              onClick={handleResetPassword}
              className={
                isVerification
                  ? "resetPassword__button"
                  : "resetPassword__buttonTwo"
              }
              disabled={!isVerification}
            >
              Wyślij
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
