import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import logo from '../../assets/logo.png';
import { customStylesTwo } from '../../utils/customStyles';
import { useAPI } from '../../context';

Modal.setAppElement('#root');

const ModalCookies = () => {

  const { cookies, setCookie } = useAPI();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (cookies.cookieConsent === 'accepted') {
      setModalIsOpen(false);
    } else {
      setModalIsOpen(true);
    }
  }, [cookies.cookieConsent]);

  const handleButtonClick = () => {
    setCookie('cookieConsent', 'accepted', { path: '/' });
    setModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStylesTwo}
      contentLabel="Cookie Policy"
    >
      <div className="modalCookies">
        <div className="modalCookies__box">
          <img className="modalCookies__logo" src={logo} alt="logo" />
          <p className="modalCookies__title">
            Polityka dotycząca plików cookies
          </p>
        </div>
        <div className="modalCookies__content">
          <p className="modalCookies__description">
            Nasza aplikacja wykorzystuje pliki cookies oraz podobne technologie,
            aby zapewnić prawidłowe funkcjonowanie oraz poprawić jakość
            świadczonych usług. Korzystając z naszej aplikacji, zgadzasz się na
            przetwarzanie danych w sposób opisany poniżej.
          </p>
          <h3 className="modalCookies__text">Co to są pliki cookies?</h3>
          <p className="modalCookies__description">
            Pliki cookies to małe pliki tekstowe, które są zapisywane na Twoim
            urządzeniu w trakcie korzystania z aplikacji. Zawierają one
            informacje, które pomagają w dostosowywaniu naszych usług do Twoich
            potrzeb oraz w zbieraniu danych statystycznych na temat korzystania
            z aplikacji.
          </p>
          <h3 className="modalCookies__text">
            Jakie pliki cookies są przez nas wykorzystywane?
          </h3>
          <ul className="modalCookies__list">
            <li className="modalCookies__item">
              1. <strong>Niezbędne pliki cookies:</strong> Te pliki są wymagane
              do prawidłowego działania naszej aplikacji. Umożliwiają m.in.
              logowanie się oraz dostęp do bezpiecznych obszarów aplikacji. Są
              to pliki, które nie wymagają Twojej zgody, gdyż ich użycie jest
              niezbędne do świadczenia usług.
            </li>
            <li className="modalCookies__item">
              2. <strong>Funkcjonalne pliki cookies:</strong> Pomagają w
              zapamiętywaniu Twoich preferencji, takich jak język czy układ
              interfejsu, co poprawia komfort korzystania z aplikacji. Zgoda na
              ich użycie jest opcjonalna, ale ich odrzucenie może wpłynąć na
              niektóre funkcje aplikacji.
            </li>
            <li className="modalCookies__item">
              3. <strong>Analityczne i wydajnościowe pliki cookies:</strong> Te
              pliki cookies pozwalają nam zbierać dane dotyczące sposobu, w jaki
              użytkownicy korzystają z naszej aplikacji. Dzięki temu możemy
              lepiej rozumieć ich zachowania i optymalizować aplikację. W tym
              celu wykorzystujemy Firebase - platformę analityczną od Google. Na
              korzystanie z tych plików cookies wymagana jest Twoja zgoda.
            </li>
            <li className="modalCookies__item">
              4. <strong>Marketingowe pliki cookies:</strong> Używane są do
              personalizowania treści marketingowych i reklam wyświetlanych
              użytkownikom. Na korzystanie z tych plików cookies wymagana jest
              Twoja zgoda, ale obecnie aplikacja ich nie wykorzystuje.
            </li>
          </ul>
          <h3 className="modalCookies__text">Firebase</h3>
          <p className="modalCookies__description">
            Nasza aplikacja korzysta z platformy Firebase, dostarczanej przez
            Google, w celu analizy zachowań użytkowników oraz poprawy
            bezpieczeństwa i wydajności. Firebase może zbierać dane takie jak
            adres IP, rodzaj urządzenia, dane lokalizacyjne, a także informacje
            o interakcjach użytkownika z aplikacją. Wszystkie dane przetwarzane
            przez Firebase są zgodne z obowiązującymi przepisami o ochronie
            danych.
          </p>
          <p className="modalCookies__description">
            Firebase wykorzystuje swoje pliki cookies oraz inne technologie
            śledzące, aby dostarczać dokładne dane analityczne oraz zapewniać
            funkcje niezbędne dla działania aplikacji. Na przetwarzanie tych
            danych wymagana jest Twoja zgoda, którą możesz wycofać w dowolnym
            momencie.
          </p>
          <h3 className="modalCookies__text">Wyrażenie zgody</h3>
          <p className="modalCookies__description">
            Podczas pierwszego uruchomienia aplikacji wyświetlona zostanie
            informacja o plikach cookies oraz prośba o wyrażenie zgody na ich
            wykorzystanie. Możesz wyrazić zgodę na wszystkie pliki cookies lub
            dokonać indywidualnych wyborów w sekcji Ustawienia zaawansowane.
          </p>
          <ul className="modalCookies__list">
            <li className="modalCookies__item">
              1. <strong>Akceptacja wszystkich plików cookies:</strong>{" "}
              Wyrażenie zgody na wszystkie kategorie plików cookies.
            </li>
            <li className="modalCookies__item">
              2. <strong>Ustawienia zaawansowane:</strong> Umożliwiają
              dostosowanie zgód na poszczególne kategorie plików cookies (np.
              funkcjonalne, analityczne) w zależności od preferencji.
            </li>
            <li className="modalCookies__item">
              3. <strong>Brak zgody i wyjście z aplikacji:</strong> Jeśli nie
              wyrazisz zgody na korzystanie z niezbędnych plików cookies,
              niektóre funkcje aplikacji nie będą działać poprawnie, dlatego
              będziesz mieć możliwość wyjścia z aplikacji bez dalszego
              korzystania z jej funkcji.
            </li>
          </ul>
          <h3 className="modalCookies__text">Zarządzanie plikami cookies</h3>
          <p className="modalCookies__description">
            W dowolnym momencie możesz zarządzać swoimi preferencjami
            dotyczącymi plików cookies w ustawieniach aplikacji, gdzie dostępna
            będzie opcja Ustawienia zaawansowane. Możesz tam:
          </p>
          <ul className="modalCookies__list">
            <li className="modalCookies__item modalCookies__item--dotted">
              Wycofać udzieloną zgodę,
            </li>
            <li className="modalCookies__item modalCookies__item--dotted">
              Zmienić ustawienia plików cookies,
            </li>
            <li className="modalCookies__item modalCookies__item--dotted">
              Wyłączyć określone typy plików cookies (z wyjątkiem niezbędnych).
            </li>
          </ul>
          <p className="modalCookies__description">
            Pamiętaj, że zmiany w ustawieniach mogą wpłynąć na funkcjonalność
            aplikacji.
          </p>
          <h3 className="modalCookies__text">Zmiany w polityce cookies</h3>
          <p className="modalCookies__description">
            Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej polityce
            dotyczącej plików cookies. Wszelkie modyfikacje będą publikowane na
            tej stronie. Zachęcamy do regularnego przeglądania polityki, aby być
            na bieżąco z ewentualnymi zmianami.
          </p>
          <button onClick={handleButtonClick} className="modalCookies__button">
            Przejdz do serwisu
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCookies;
