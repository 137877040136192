import Modal from "react-modal";
import { customStylesThree } from "../../utils/customStyles";
import trashBin from "../../assets/trash_bin.png";
import { useAPI } from "../../context";
import { arrayRemove, doc, runTransaction } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { toast } from "react-toastify";
import { useState } from "react";
import Spinner from "../../common/spinner";
import { MembersType } from "../../context/@types.context";

type ModalMembersType = {
  isOpenModal: boolean;
  closeModal: () => void;
};

const ModalMembers = ({ isOpenModal, closeModal }: ModalMembersType) => {
  const { remont, setRemont } = useAPI();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const removeMember = async (item: MembersType) => {
    setIsLoading(true);
  
    const documentId = remont.key;
    
    if (!documentId) {
      console.error("Document ID is undefined.");
      toast.error("Document ID is missing.", {
        autoClose: 3000,
      });
      setIsLoading(false);
      return;
    }
  
    const member = remont.employeeKeys?.find(element => element.key === item.employeeKey);
  
    if (member?.isActive) {
      const collectionId = "remonts";

      if (!documentId) {
        throw new Error("Remont key is undefined");
      }
  
      try {
        const docRef = doc(db, collectionId, documentId);
  
        await runTransaction(db, async (transaction) => {
          transaction.update(docRef, {
            members: arrayRemove(item),
          });
  
          transaction.update(docRef, {
            employeeKeys: arrayRemove({
              isActive: true,
              key: item.employeeKey,
            }),
          });
        });
  
        toast.success("Usunięto pracownika", {
          autoClose: 3000,
        });
  
        setRemont(prevRemont => ({
          ...prevRemont,
          members: prevRemont.members?.filter(member => member.employeeKey !== item.employeeKey) || [],
          employeeKeys: prevRemont.employeeKeys?.filter(keyObj => keyObj.key !== item.employeeKey) || [],
        }));
  
      } catch (error) {
        console.error("Błąd usuwania:", error);
        toast.error("Coś poszło nie tak", {
          autoClose: 3000,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("Pracownik nie został znaleziony lub nie jest aktywny", {
        autoClose: 3000,
      });
      setIsLoading(false);
    }
  };
  
  return (
    <Modal
      isOpen={isOpenModal}
      style={customStylesThree}
      onRequestClose={closeModal}
    >
      <div className="modalMembers">
        {isLoading ? <Spinner /> : null}
        {remont?.members?.map((item, index) => (
          <>
            
            <div key={index} className="modalMembers__box">
              <p className="modalMembers__text">{index + 1}.</p>
              <p className="modalMembers__text">{item.name}</p>
              <span className="modalMembers__item">-</span>
              <p className="modalMembers__text">{item.specialization}</p>
              <button
                className="modalMembers__buttonRemove"
                onClick={() => removeMember(item)}
              >
                <img
                  className="modalMembers__icon"
                  src={trashBin}
                  alt="trash"
                />
              </button>
            </div>
          </>
        ))}
      </div>
    </Modal>
  );
};

export default ModalMembers;
