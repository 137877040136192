import { SectionType } from "../../context/@types.context";

const Overlap = ({ dispatch, title }: SectionType) => {
  return (
    <div className="overlap">
      <button className="overlap__back" onClick={dispatch}>
        &lt;
      </button>
      <p className="overlap__name">{title}</p>
    </div>
  );
};

export default Overlap;