/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAPI } from "../context";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

const useProfile = () => {
  const { cookies, setLoadingProfile, setDataUser } = useAPI();
  const [error, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (cookies.token) {
        try {
          const uid = localStorage.getItem("uid");

          if (uid) {
            const userDocRef = doc(db, "users", uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
              const userData = userDoc.data();

              const completeUserData = {
                id: userDoc.id,
                name: userData.name,
                email: userData.email,
              };

              setDataUser(completeUserData);
            }
          }
        } catch (error) {
          setIsError(true);
          console.error("Error fetching user:", error);
        } finally {
          setLoadingProfile(true);
        }
      }
    };

    fetchData();
  }, [cookies.token]);

  return { error, setIsError };
};

export default useProfile;
