import { Button, Tooltip } from "antd";
import dayjs from "dayjs";
import "antd/dist/reset.css";
import PhotoUpload from "../photoUpload";
import Spinner from "../../common/spinner";
import trashBin from "../../assets/trash_bin.png";
import { useAPI } from "../../context";
import useRemoveEntry from "../../hooks/useRemoveEntry";
import { formatterText } from "../../utils/formatterText/formatterText";
import { Dispatch, SetStateAction } from "react";
import { EntryType } from "../../context/@types.context";

interface TabUserProps {
  tab: EntryType[];
  setTab: Dispatch<SetStateAction<EntryType[]>>
  name: string;
  uploadFunction: () => void;
  loading: boolean;
  value: string;
  setValue: (a: string) => void
}

const TabUser: React.FC<TabUserProps> = ({
  tab,
  setTab,
  name,
  uploadFunction,
  loading,
  value,
  setValue
}) => {
  const convertDate = (timestamp: number) => {
    return dayjs.unix(timestamp).format("DD-MM-YYYY");
  };

  const { employeeLS } = useAPI();
  const { removeEntry, isLoading,  } = useRemoveEntry(setTab, name);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = evt.target.value;
    value.endsWith('\n');
    setValue(value);
  };

  const handleRemove = async (item: EntryType) => {
    await removeEntry(item);
  };

  const sortedTab = tab?.sort((a: EntryType, b: EntryType) => {
    return (b.createdTime ?? 0) - (a.createdTime ?? 0);
  });

  return (
    <div className="tabUser">
      {isLoading ? <Spinner /> : null}
      {name === "constructionZone" && (
        <>
          <div className="renovation__wrapper">
            {loading ? <Spinner /> : null}
            <textarea
              maxLength={500}
              className="renovation__value"
              placeholder="Prace na statku"
              name={name}
              value={value}
              onChange={(e) => handleChange(e)}
            />
            <PhotoUpload />
            <div className="renovation__wrapperButton">
              <Tooltip
                title="Kliknij, aby udostępnić publicznie twój wpis"
                placement="top"
              >
                <Button
                  type="primary"
                  onClick={() => uploadFunction()}
                  className="renovation__buttonAdd renovation__buttonAdd--color"
                >
                  Udostępnij
                </Button>
              </Tooltip>
            </div>
          </div>
        </>
      )}

      {sortedTab?.length === 0 ? (
        <p className="tabUser__text">brak informacji</p>
      ) : (
        sortedTab?.map((item, index) =>
          !item.isPrivate ? (
            <div className="tabUser__wrapper" key={index}>
              {item.uid === employeeLS && name === "constructionZone" ? (
                <div className="tabUser__header">
                  <button onClick={() => handleRemove(item)} className="tabUser__iconButton">
                    <img className="tabUser__iconImage" src={trashBin} alt="trash"/>
                  </button>
                </div>
              ) : null}
              <div className="tabUser__box">
                {name === "constructionZone" ? (
                  <p className="tabUser__implementation">{item.name}</p>
                ) : null}
                <p className="tabUser__description">{formatterText(item.description || "")}</p>
                {item.link && (
                  <img className="tabUser__image" src={item.link} alt="name" />
                )}
                {name === "schedule" && (
                  <p className="tabUser__implementation">
                    czas realizacji {convertDate(item.startTimestamp || 0)} -{" "}
                    {convertDate(item.endTimestamp || 0)}
                  </p>
                )}
                <p className="tabUser__time">
                  data utworzenia: {convertDate(item.createdTime || 0)}
                </p>
              </div>
            </div>
          ) : null
        )
      )}
    </div>
  );
};

export default TabUser;
