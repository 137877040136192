import { useState, useContext } from "react";
import { APIContext, ListType, RemontType, DataUserType, APIContextProviderProps } from "./@types.context";
import { useCookies } from 'react-cookie';
import useLocalstorage from "../hooks/useLocalStorage";

export const APIContextProvider = ({ children }: APIContextProviderProps) => {
  const [isHamburgerMenu, setIsHamburgerMenu] = useState<boolean>(false);
  const [list, setList] = useState<ListType[]>([]);
  const [remont, setRemont] = useState<RemontType>({
    name: "",
    description: "",
    key: "",
    completed: false,
    constructionZone: [],
    noticeBoard: [],
    schedule: [],
    specifications: [],
    employeeKeys: [],
    members: [],
    admin: "",
    createdTime: 0,
  });
  const [cookies, setCookie, removeCookie] = useCookies<string>(['token']);

  const [dataUser, setDataUser] = useState<DataUserType>({ name: "", email: "", password: "", id: "" });
  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [base64Data, setBase64Data] = useState<string | null>(null);

  const { remontLS, setRemontLS, employeeLS, setEmployeeLS } = useLocalstorage();

  return (
    <APIContext.Provider
      value={{
        isHamburgerMenu,
        setIsHamburgerMenu,
        list,
        setList,
        remont,
        setRemont,
        cookies,
        setCookie,
        removeCookie,
        dataUser,
        setDataUser,
        loadingProfile,
        setLoadingProfile,
        isShow,
        setIsShow,
        selectedFile,
        setSelectedFile,
        base64Data,
        setBase64Data,
        remontLS,
        setRemontLS,
        employeeLS,
        setEmployeeLS,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export function useAPI() {
  const context = useContext(APIContext);
  return context;
}