import images from "../../imagesList";
import { useReducer } from "react";
import { useAPI } from "../../context";
import useProfile from "../../hooks/useProfile";
import EditProfile from "../editProfile";
import Subscription from "../subscription";
import { Link } from "react-router-dom";
import Spinner from "../../common/spinner";
import Error from "../error";
import Regulamin from "../regulamin";
import PrivacyPolicy from "../privacyPolicy";

type AdminStateType = {
  component: JSX.Element | null;
  isOption: boolean;
};

type AdminActionType =
  | { type: "Edytuj profil"; dispatch: React.Dispatch<AdminActionType> }
  | { type: "Abonament"; dispatch: React.Dispatch<AdminActionType> }
  | { type: "Regulamin"; dispatch: React.Dispatch<AdminActionType> }
  | { type: "Polityka prywatności"; dispatch: React.Dispatch<AdminActionType> }
  | { type: "Wyloguj się" }
  | { type: "Wyłącz opcje" };

const Admin = () => {
  const { avatar } = images;

  const { removeCookie, dataUser, loadingProfile, setLoadingProfile } = useAPI();
  const { error } = useProfile();

  const initialState: AdminStateType = { component: null, isOption: false };

  function reducer(state: AdminStateType, action: AdminActionType): AdminStateType {
    const start = () => {
      if ('dispatch' in action) {
        action.dispatch({ type: "Wyłącz opcje" });
      }
    };

    switch (action.type) {
      case "Edytuj profil":
        return {
          ...state,
          component: <EditProfile dispatch={start} />,
          isOption: true,
        };
      case "Abonament":
        return {
          ...state,
          component: <Subscription dispatch={start} />,
          isOption: true,
        };
      case "Regulamin":
        return {
          ...state,
          component: <Regulamin dispatch={start} title="Regulamin" />,
          isOption: true,
        };
      case "Polityka prywatności":
        return {
          ...state,
          component: <PrivacyPolicy dispatch={start} title="Polityka prywatności" />,
          isOption: true,
        };
      case "Wyloguj się":
        return { ...state, isOption: false };
      case "Wyłącz opcje":
        return { ...state, isOption: false };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const arrayOptions: AdminActionType['type'][] = 
  ["Edytuj profil", "Abonament", "Regulamin", "Polityka prywatności", "Wyloguj się"];

  const loggOut = (item: AdminActionType['type']) => {
    dispatch({ type: item, dispatch });
    if (item === "Wyloguj się") {
      removeCookie("token");
      setLoadingProfile(false);
    }
  };

  return (
    <div className="admin">
      {loadingProfile ? (
        <>
          <div className={state.isOption ? "admin__optionOn" : "admin__optionOff"}>
            {state.component}
          </div>
          <h1 className="admin__title">Mój profil</h1>
          <div className="admin__figure">
            <img className="admin__image" src={avatar} alt="avatar" />
          </div>
          <p className="admin__name">{dataUser.name}</p>
          <div className="admin__wrapper">
            <Link className="admin__link" to="/creator">
              Eksploatacja / Remont
            </Link>
            <Link className="admin__link" to="/repairs">
              Lista
            </Link>
            {arrayOptions.map((item, index) => (
              <button className="admin__button" onClick={() => loggOut(item)} key={index}>
                {item}
              </button>
            ))}
          </div>
        </>
      ) : error ? (
        <Error />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Admin;
