const Spinner = () => {
    return (
      <div className="spinner">
          <div className="ldsRing">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
      </div>
    );
  };
  
  export default Spinner;
  