import images from "../../imagesList";
import { useAPI } from "../../context";
import { useState } from "react";
import { db } from "../../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../common/spinner";
import Overlap from "../../common/overlap";

type EditProfileType = {
  dispatch: () => void;
};

const EditProfile = ({ dispatch }: EditProfileType) => {
  const { avatar, write } = images;
  
  const { dataUser, setDataUser } = useAPI();

  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({
    name: dataUser.name,
    id: dataUser.id,
    password: dataUser.password,
  });
  const { name } = editData;

  const handleChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    setEditData({ ...editData, [evt.target.name]: value });
  };

  const editProfile = async () => {
    setLoading(true);

    const uid = localStorage.getItem("uid");

    if (!uid) {
      return;
    }

    try {
      const userDocRef = doc(db, "users", uid);
      await updateDoc(userDocRef, { name: name });
      toast.success("Dane zostały zmienione", {
        autoClose: 3000,
      });

      setDataUser(prevData => ({
        ...prevData,
        name: name,
      }));
    } catch (error) {
      toast.error("Wystąpił błąd podczas zmiany danych.", {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="editProfile">
      <ToastContainer theme="colored" progressClassName="custom-progress-bar" />
      <Overlap dispatch={dispatch} title="Edytuj profil" />
      <div className="editProfile__figure">
        <img className="editProfile__image" src={avatar} alt="avatar" />
        <button className="editProfile__buttonEdit">
          <img className="editProfile__icon" src={write} alt="avatar" />
        </button>
      </div>
      <div className="editProfile__container">
        <form className="editProfile__form" onSubmit={(event) => event.preventDefault()}>
          <input
            maxLength={50}
            onChange={(e) => handleChange(e)}
            className="editProfile__item"
            placeholder="Imię i nazwisko"
            value={name}
            name="name"
          />
          <input
            maxLength={50}
            onChange={(e) => handleChange(e)}
            className="editProfile__item editProfile__item-color"
            placeholder="Email"
            value={dataUser.email}
            disabled={true}
          />
        </form>
        {loading ? (
            <Spinner />
        ) : (
          <>
            <button className="editProfile__buttonSave" onClick={editProfile}>
              Zatwiedz
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default EditProfile;
