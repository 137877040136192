/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useState } from 'react';
import { arrayRemove, doc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db, storage } from '../firebase/firebase';
import { useAPI } from '../context';
import { deleteObject, ref } from 'firebase/storage';
import { EntryType, DashboardTabsType } from '../context/@types.context';

const useRemoveEntry = (
  setTab: Dispatch<SetStateAction<EntryType[]>>, 
  type: string
) => {

  const { setRemont, remont } = useAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const removeEntry = async (item: EntryType) => {

    if (remont.completed) {
      toast.info("Eksploatacja / Remont został zakończony nie możesz usunąć wpisu", { autoClose: 3000 });
      return;
    }

    const documentId = remont.key;
    const collectionId = "remonts";

    setIsLoading(true);

    if (!documentId) {
      throw new Error("Remont key is undefined");
    }

    try {
      const docRef = doc(db, collectionId, documentId);
      await updateDoc(docRef, {
        [type]: arrayRemove(item),
      });

      if (item.link) {
        const imageRef = ref(storage, item.link);
        await deleteObject(imageRef);
      }

      toast.success('Wpis został usunięty', {
        autoClose: 3000,
      });

      setTab((prevTab) => {
        if (prevTab.length === 0) return prevTab;

        if ('name' in prevTab[0]) {
          return prevTab.filter((element) => element.id !== item.id);
        } else {
          return prevTab.filter((element) => element.id !== item.id);
        }
      });

      setRemont((prevRemont) => ({
        ...prevRemont,
        [type as DashboardTabsType]: (prevRemont[type as DashboardTabsType] || []).filter(
          (element) => element.id !== item.id
        ),
      }));

    } catch (error) {
      console.error('Błąd usuwania:', error);
      toast.error('Coś poszło nie tak', {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, removeEntry };
};

export default useRemoveEntry;