/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAPI } from "../../context";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../common/spinner";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const { setCookie, cookies } = useAPI();
  const [login, setLogin] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  
  const { email, password } = login;

  const logged = async () => {
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;

      if (!user.emailVerified) {
        toast.error("Twój adres e-mail nie został zweryfikowany. Sprawdź skrzynkę pocztową i kliknij link weryfikacyjny.", {
          autoClose: 5000,
        });
        setLoading(false);
        return;
      }

      const accessToken = await user.getIdToken();
      const uid = user.uid;

      localStorage.setItem("uid", uid);
      setCookie("token", accessToken, { path: "/" });
      navigate("/")
    } catch (error) {
      toast.error("Wystąpił błąd podczas logowania. Spróbuj ponownie.", {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
      setLogin({ email: "", password: "" });
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    setLogin({ ...login, [evt.target.name]: value });
  };

  useEffect(() => {
    if (cookies.token) {
      navigate("/");
    }
  }, [cookies.token, navigate]);

  return (
    <div className="login">
      <h1 className="login__title">Logowanie</h1>
      <div className="login__wrapper">
        <ToastContainer
          theme="colored"
          progressClassName="custom-progress-bar"
        />
        {loading ? <Spinner /> : null}
        {!loading ? (
          <form
            className="login__form"
            onSubmit={(event) => event.preventDefault()}
          >
            <input
              maxLength={50}
              onChange={(e) => handleChange(e)}
              className="login__item"
              placeholder="Email"
              type="text"
              value={email}
              name="email"
            />
            <input
              onChange={(e) => handleChange(e)}
              className="login__item"
              placeholder="Hasło"
              type="password"
              value={password}
              name="password"
              maxLength={50}
            />
            <Link className="login__linkTwo" to="/passwordReminder">
              Nie pamiętam hasła
            </Link>
            <button
              className="login__item login__item--submit"
              onClick={logged}
            >
              Zaloguj się
            </button>
          </form>
        ) : null}
        {!loading ? (
          <p className="login__text">Nie posiadasz jeszcze konta ?</p>
        ) : null}
        {!loading ? (
          <Link className="login__link" to="/register">
            Zarejestruj się
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default Login;
