import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { auth } from "../../firebase/firebase";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../common/spinner";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [oobCode, setOobCode] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean | null>(null);

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return `Hasło musi mieć co najmniej ${minLength} znaków.`;
    }
    if (!hasUpperCase) {
      return "Hasło musi zawierać co najmniej jedną wielką literę.";
    }
    if (!hasLowerCase) {
      return "Hasło musi zawierać co najmniej jedną małą literę.";
    }
    if (!hasNumber) {
      return "Hasło musi zawierać co najmniej jedną cyfrę.";
    }
    if (!hasSpecialChar) {
      return "Hasło musi zawierać co najmniej jeden znak specjalny.";
    }

    return null;
  };

  useEffect(() => {
    const code = searchParams.get("oobCode");

    if (code) {
      setOobCode(code);

      verifyPasswordResetCode(auth, code)
        .then(() => {
          setIsError(false)
          setIsLoading(false);
        })
        .catch((error) => {
          setIsError(true)
          setError("Link do resetowania hasła jest nieważny lub wygasł.");
          setIsLoading(false);
        });
    } else {
      toast.error("Brak kodu w linku resetowania hasła.");
      setError("Brak kodu w linku resetowania hasła.");
      setIsLoading(false);
    }
  }, [searchParams]);

  const validatePasswords = () => {
    const passwordError = validatePassword(newPassword);

    if (passwordError) {
      toast.error(passwordError, {
        autoClose: 3000,
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Hasła nie są zgodne");
      return false;
    }
    setError(null);
    return true;
  };

  const handleResetPassword = async () => {
    setIsLoading(true);

    if (!validatePasswords()) {
      setIsLoading(false);
      return;
    }

    if (oobCode) {
      try {
        await confirmPasswordReset(auth, oobCode, newPassword);
        setIsSuccess(true);
        toast.success(
          "Hasło zostało zaktualizowane. Możesz teraz się zalogować."
        );
      } catch (error) {
        const firebaseError = error as { code?: string; message?: string };
        if (firebaseError.code === "auth/invalid-action-code") {
          toast.error(
            "Link do resetowania hasła jest nieważny lub został już wykorzystany."
          );
        } else {
          toast.error(
            "Wystąpił błąd podczas aktualizacji hasła. Spróbuj ponownie."
          );
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isError === null) {
    return <Spinner />
  }
 
  return (
    <div className="resetPassword">
      <ToastContainer theme="colored" progressClassName="custom-progress-bar" />
      <h1 className="resetPassword__title">Reset hasła</h1>
      <div className="resetPassword__wrapper">
        {isLoading ? <Spinner /> : null}
        {isSuccess ? (
          <Link className="resetPassword__linkBack" to="/login">
            Wróć na stronę logowania
          </Link>
        ) : (
          <>
            <p className="resetPassword__text">
              Wprowadź poniżej nowe hasło oraz jego potwierdzenie
            </p>
            {error && <p className="resetPassword__error">{error}</p>}
            <>
              {error ===
              "Link do resetowania hasła jest nieważny lub wygasł." ? null : (
                !isError ? <>
                  <input
                    maxLength={50}
                    type="password"
                    placeholder="Nowe hasło"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="resetPassword__input"
                  />
                  <input
                    maxLength={50}
                    type="password"
                    placeholder="Potwierdź nowe hasło"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="resetPassword__input"
                  />
                  <button
                    onClick={handleResetPassword}
                    className="resetPassword__button"
                    disabled={isLoading || !newPassword || !confirmPassword}
                  >
                    Zaktualizuj hasło
                  </button>
                </> : null
              )}
            </>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
