import Modal from "react-modal";
import { customStylesTwo } from "../../utils/customStyles";

interface ModalAboutUsType {
  isOpenModal: boolean;
  closeModal: () => void;
}

const ModalAboutUs = ({isOpenModal, closeModal}: ModalAboutUsType) => {

  Modal.setAppElement("#root");

  return (
    <Modal isOpen={isOpenModal} style={customStylesTwo} onRequestClose={closeModal}>
      <div className="ModalAboutUs">
        <h2 className="ModalAboutUs__title">O nas</h2>
        <p className="ModalAboutUs__description">Jesteśmy dynamicznie rozwijającym się startupem, który łączy nowoczesne technologie z pasją do optymalizacji procesów produkcyjnych i biznesowych. Nasz zespół składa się ze specjalistów z dziedziny IT, marketingu oraz zarządzania, którzy z pełnym zaangażowaniem pracują nad tworzeniem rozwiązań CRM (Customer Relationship Management).</p>
        <p className="ModalAboutUs__description">Nasze aplikacje CRM są projektowane z myślą o użytkownikach – są intuicyjne, elastyczne i dopasowane do specyficznych potrzeb różnych branż.</p>
        <p className="ModalAboutUs__description">Stawiamy na nowoczesne technologie. Naszym celem jest nie tylko tworzenie oprogramowania, ale także wspieranie firm w procesie transformacji cyfrowej, co pozwala im lepiej zarządzać swoimi zasobami, poprawiać efektywność i zwiększać zyski.</p>
        <p className="ModalAboutUs__description">Współpracujemy z małymi i średnimi przedsiębiorstwami, jak również z dużymi korporacjami, oferując im rozwiązania skrojone na miarę ich potrzeb. Wierzymy w partnerstwo oparte na zaufaniu, dlatego nieustannie rozwijamy nasze produkty, aby sprostać zmieniającym się wymaganiom rynku.</p>
        <p className="ModalAboutUs__description">Dołącz do nas i zobacz, jak nasze rozwiązania CRM mogą pomóc Twojej firmie osiągnąć nowe wyżyny!</p>
        <p className="ModalAboutUs__description">Ten tekst podkreśla kluczowe elementy działalności startupu, takie jak innowacyjność, zorientowanie na klienta i zaangażowanie w rozwój technologii, co jest istotne w kontekście tworzenia aplikacji CRM.</p>
      </div>
    </Modal>
  );
};

export default ModalAboutUs;