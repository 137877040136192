import { useAPI } from "../../context";
import { useState } from "react";
import useKey from "../../hooks/useKey";
import useProfile from "../../hooks/useProfile";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import dayjs from "dayjs";
import Spinner from "../../common/spinner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Creator = () => {
  const [remont, setRemont] = useState({ name: "", description: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { loadingKey, key } = useKey(loading);
  const navigate = useNavigate();
  const { cookies } = useAPI();
  useProfile();

  const create = async () => {
    setLoading(true);
    setError(false);

    const uid = localStorage.getItem("uid");
    if (!uid) {
      setLoading(false);
      return;
    }

    try {
      const timestamp = dayjs().unix();
      const myKey = key.join("");

      const docRef = doc(db, "remonts", myKey);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        throw new Error("Klucz już istnieje. Proszę spróbować ponownie.");
      }

      const data = {
        ...remont,
        key: myKey,
        completed: false,
        createdRemont: uid,
        createdTime: timestamp,
        members: [],
        constructionZone: [],
        schedule: [],
        noticeBoard: [],
        specifications: [],
        employeeKeys: [],
      };

      await setDoc(docRef, data);

      setRemont({ name: "", description: "" });
      navigate("/repairs");
    } catch (error) {
      setError(true);
      toast.error("Coś poszło nie tak. Proszę spróbować ponownie.", {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = evt.target;
    setRemont((prev) => ({ ...prev, [name]: value }));
  };

  const verification = !remont.name || !remont.description;

  return (
    <div className="creator">
      {!error ? (
        <div className="creator__wrapper">
          {loading && <Spinner />}
          {cookies.token && (
            <div className="creator__container">
              <h2 className="creator__title">Eksploatacja / Remont</h2>
              <p className="creator__text">Wpisz poniżej nazwę jednostki oraz opis</p>
              <form
                className="creator__form"
                onSubmit={(event) => event.preventDefault()}
              >
                <input
                  maxLength={50}
                  className="creator__item"
                  value={remont.name}
                  name="name"
                  onChange={handleChange}
                  placeholder="Nazwa jednostki"
                />
                <textarea
                  maxLength={500}
                  className="creator__item creator__item--area"
                  value={remont.description}
                  name="description"
                  placeholder="Opis"
                  onChange={handleChange}
                />
                <div className="creator__box">
                  {!loadingKey &&
                    key.map((digit, index) => (
                      <div className="creator__itemTwo" key={index}>
                        {digit}
                      </div>
                    ))}
                </div>
                {!loadingKey ? (
                  <button
                    className={`creator__item ${
                      verification
                        ? "creator__item--submitTwo"
                        : "creator__item--submit"
                    }`}
                    disabled={verification}
                    onClick={create}
                  >
                    start
                  </button>
                ) : (
                  <div className="creator__file">
                    <Spinner />
                  </div>
                )}
              </form>
            </div>
          )}
        </div>
      ) : (
        <div className="creator__wrapper">
          <p className="creator__text">Coś poszło nie tak, spróbuj później</p>
        </div>
      )}
    </div>
  );
};

export default Creator;
