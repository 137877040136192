import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { applyActionCode } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../common/spinner";

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    const verifyEmail = async () => {
      const oobCode = searchParams.get("oobCode");
      if (!oobCode) {
        toast.error("Nieprawidłowy link weryfikacyjny.", { autoClose: 3000 });
        setLoading(false);
        return;
      }

      try {
        await applyActionCode(auth, oobCode);
        setVerified(true);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [searchParams]);

  return (
    <div className="emailVerification">
      <ToastContainer theme="colored" progressClassName="custom-progress-bar" />
      {loading ? <Spinner /> : null}
      <div className="emailVerification__wrapper">
        {!loading && verified ? (
          <h1>
            Twój adres e-mail został zweryfikowany. Możesz się teraz{" "}
            <Link className="emailVerification__text" to="/login">
            {" "}
                zalogować
            </Link>
          </h1>
        ) : !loading ? (
          <h1>Nie udało się zweryfikować adresu e-mail.</h1>
        ) : null}
      </div>
    </div>
  );
};

export default EmailVerification;
