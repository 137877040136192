import { SectionType } from "../../context/@types.context";
import Section from "../section";

const Regulamin = ({ dispatch, title }: SectionType) => {
  return (
    <Section dispatch={dispatch} title={title || ""}>
      <div className="regulamin">
        <p className="regulamin__text regulamin__text--title">
          1.Postanowienia ogólne
        </p>
        <p className="regulamin__text">
          1.1. Niniejszy Regulamin określa zasady korzystania z aplikacji CRM
          vesselhost.com, dostarczanej przez [sp.z.o.o, z siedzibą w
          Gdańsku], wpisanej do rejestru przedsiębiorców pod numerem [numer KRS
          lub NIP], zwanej dalej "Dostawcą".
        </p>
        <p className="regulamin__text">
          1.2. Przed rozpoczęciem korzystania z Aplikacji, Użytkownik jest
          zobowiązany do zapoznania się z niniejszym Regulaminem oraz jego
          akceptacji.
        </p>
        <p className="regulamin__text">
          1.3. Korzystanie z Aplikacji jest równoznaczne z akceptacją
          niniejszego Regulaminu przez Użytkownika.
        </p>
        <p className="regulamin__text regulamin__text--title">2. Definicje</p>
        <p className="regulamin__text">
          2.1. Aplikacja – oprogramowanie dostarczane przez Dostawcę,
          umożliwiające zarządzanie relacjami z klientami (CRM).
        </p>
        <p className="regulamin__text">
          2.2. Użytkownik – osoba fizyczna lub prawna korzystająca z Aplikacji
          na podstawie zaakceptowanego Regulaminu.
        </p>
        <p className="regulamin__text">
          2.3. Konto – indywidualne konto Użytkownika w Aplikacji, utworzone w
          wyniku rejestracji.
        </p>

        <p className="regulamin__text">
          2.4. Dane Użytkownika – wszelkie dane wprowadzone do Aplikacji przez
          Użytkownika.
        </p>
        <p className="regulamin__text regulamin__text--title">
          3. Rejestracja i Konto Użytkownika
        </p>
        <p className="regulamin__text">
          3.1. W celu korzystania z Aplikacji, Użytkownik musi dokonać
          rejestracji i utworzyć Konto.
        </p>
        <p className="regulamin__text">
          3.2. Użytkownik jest zobowiązany do podania prawdziwych, aktualnych i
          kompletnych danych podczas rejestracji.
        </p>
        <p className="regulamin__text">
          3.3. Użytkownik zobowiązuje się do utrzymania poufności swojego hasła
          i innych danych logowania oraz do nieudostępniania ich osobom trzecim.
        </p>
        <p className="regulamin__text">
          3.4. Dostawca nie ponosi odpowiedzialności za skutki korzystania z
          Konta przez osoby trzecie, którym Użytkownik udostępnił dane
          logowania.
        </p>
        <p className="regulamin__text regulamin__text--title">
          4. Zasady korzystania z Aplikacji
        </p>
        <p className="regulamin__text">
          4.1. Użytkownik zobowiązuje się do korzystania z Aplikacji zgodnie z
          jej przeznaczeniem, obowiązującym prawem oraz zasadami niniejszego
          Regulaminu.
        </p>
        <p className="regulamin__text">
          4.2. Użytkownik nie może korzystać z Aplikacji w sposób, który mógłby
          prowadzić do naruszenia prawa, w tym praw własności intelektualnej,
          ochrony danych osobowych oraz dóbr osobistych innych osób.
        </p>
        <p className="regulamin__text">
          4.3. Użytkownik nie jest uprawniony do ingerowania w kod źródłowy
          Aplikacji, dekompilacji ani prób jego modyfikacji.
        </p>
        <p className="regulamin__text regulamin__text--title">
          5. Odpowiedzialność Dostawcy
        </p>
        <p className="regulamin__text">
          5.1. Dostawca dokłada wszelkich starań, aby zapewnić ciągłość
          działania Aplikacji, jednakże nie gwarantuje, że Aplikacja będzie
          dostępna bez przerw oraz że będzie wolna od błędów.
        </p>
        <p className="regulamin__text">
          5.2. Dostawca nie ponosi odpowiedzialności za jakiekolwiek szkody
          wynikające z niewłaściwego korzystania z Aplikacji przez Użytkownika,
          ani za szkody powstałe wskutek przerw w działaniu Aplikacji, awarii
          technicznych lub utraty danych.
        </p>
        <p className="regulamin__text">
          5.3. Dostawca zastrzega sobie prawo do czasowego zawieszenia dostępu
          do Aplikacji w celu przeprowadzenia prac konserwacyjnych lub
          aktualizacji oprogramowania.
        </p>
        <p className="regulamin__text regulamin__text--title">
          6. Ochrona danych osobowych
        </p>
        <p className="regulamin__text">
          6.1. Dane osobowe Użytkownika przetwarzane są zgodnie z obowiązującymi
          przepisami prawa, w szczególności z ustawą o ochronie danych osobowych
          oraz RODO.
        </p>
        <p className="regulamin__text">
          6.2. Szczegółowe informacje na temat przetwarzania danych osobowych
          znajdują się w Polityce Prywatności, stanowiącej integralną część
          niniejszego Regulaminu.
        </p>
        <p className="regulamin__text regulamin__text--title">7. Płatności</p>
        <p className="regulamin__text">
          7.1. Korzystanie z Aplikacji może być płatne zgodnie z cennikiem
          przedstawionym przez Dostawcę.
        </p>
        <p className="regulamin__text">
          7.2. Użytkownik zobowiązuje się do terminowego regulowania opłat za
          korzystanie z Aplikacji.
        </p>
        <p className="regulamin__text">
          7.3. W przypadku braku płatności w terminie, Dostawca zastrzega sobie
          prawo do zablokowania dostępu do Aplikacji.
        </p>
        <p className="regulamin__text regulamin__text--title">
          8. Rozwiązanie umowy
        </p>
        <p className="regulamin__text">
          8.1. Użytkownik może w każdej chwili zrezygnować z korzystania z
          Aplikacji, usuwając swoje Konto.
        </p>
        <p className="regulamin__text">
          8.2. Dostawca zastrzega sobie prawo do rozwiązania umowy i usunięcia
          Konta Użytkownika w przypadku naruszenia przez niego postanowień
          Regulaminu.
        </p>
        <p className="regulamin__text">
          8.3. W przypadku rozwiązania umowy, wszystkie dane Użytkownika mogą
          zostać usunięte z Aplikacji.
        </p>
        <p className="regulamin__text regulamin__text--title">
          9. Postanowienia końcowe
        </p>
        <p className="regulamin__text">
          9.1. Dostawca zastrzega sobie prawo do zmiany niniejszego Regulaminu.
          O każdej zmianie Użytkownik zostanie poinformowany z odpowiednim
          wyprzedzeniem.
        </p>
        <p className="regulamin__text">
          9.2. Wszelkie spory wynikłe w związku z korzystaniem z Aplikacji będą
          rozstrzygane przez sąd właściwy dla siedziby Dostawcy.
        </p>
        <p className="regulamin__text">
          9.3. W sprawach nieuregulowanych niniejszym Regulaminem mają
          zastosowanie przepisy prawa polskiego.
        </p>
      </div>
    </Section>
  );
};

export default Regulamin;
