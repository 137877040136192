import { Link } from "react-router-dom";
import { useAPI } from "../../context";
import Employee from "../../components/employee";
import Admin from "../../components/admin";

const Home = () => {
  const { cookies, remontLS, employeeLS } = useAPI();

  const renderContent = () => {
    if (cookies.token) {
      return <Admin />;
    }

    if (remontLS === "" && employeeLS === "") {
      return (
        <div className="home">
          <div className="home__wrapper">
            <div className="home__gradient" />
            <Link className="home__link" to="/login">Zaloguj się</Link>
            <Link className="home__link" to="/joinTheRenovation">Dołącz jako pracownik</Link>
          </div>
        </div>
      );
    }

    return <Employee />;
  };

  return (
    <>
      {renderContent()}
    </>
  );
};

export default Home;