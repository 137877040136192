import { DatePicker, Button, Tooltip } from "antd";
import dayjs, { Dayjs } from "dayjs";
import "antd/dist/reset.css";
import PhotoUpload from "../photoUpload";
import Spinner from "../../common/spinner";
import trashBin from "../../assets/trash_bin.png";
import useRemoveEntry from "../../hooks/useRemoveEntry";
import { formatterText } from "../../utils/formatterText/formatterText";
import { EntryType } from "../../context/@types.context";
import { Dispatch, SetStateAction } from "react";

const { RangePicker } = DatePicker;

interface TabAdminProps {
  tab: EntryType[];
  setTab: Dispatch<SetStateAction<EntryType[]>>
  name: string;
  uploadFunction: (item: boolean) => Promise<void>;
  loading: boolean;
  admin: { noticeBoard: string; specifications: string; schedule: string };
  setAdmin: Dispatch<SetStateAction<{ 
    noticeBoard: string; 
    specifications: string; 
    schedule: string; 
  }>>;
  selectedRange: [Dayjs, Dayjs] | null;
  setSelectedRange: (range: [Dayjs, Dayjs] | null) => void;
}

const TabAdmin: React.FC<TabAdminProps> = ({
  tab,
  setTab,
  name,
  uploadFunction,
  loading,
  admin,
  setAdmin,
  selectedRange,
  setSelectedRange,
}) => {
  const handleDateChange = (dates: [Dayjs, Dayjs] | null) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      setSelectedRange([startDate, endDate]);
    } else {
      setSelectedRange(null);
    }
  };

  const { removeEntry, isLoading } = useRemoveEntry(setTab, name);

  const convertDate = (timestamp: number) => {
    return dayjs.unix(timestamp).format("DD-MM-YYYY");
  };

  const getValue = () => {
    switch (name) {
      case "noticeBoard":
        return admin.noticeBoard;
      case "schedule":
        return admin.schedule;
      case "specifications":
        return admin.specifications;
      default:
        return undefined;
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = evt.target.value;
    setAdmin({ ...admin, [evt.target.name]: value });
  };

  const handleRemove = async (item: EntryType) => {
    await removeEntry(item);
  };

  const uid = localStorage.getItem("uid");

  const sortedTab = tab?.sort((a, b) => {
    return (b.createdTime ?? 0) - (a.createdTime ?? 0);
  });  

  return (
    <div className="tabAdmin">
      {isLoading ? <Spinner /> : null}
      {name !== "constructionZone" ? (
        <div className="renovation__wrapper">
          {loading ? <Spinner /> : null}
          <textarea
            maxLength={500}
            className="renovation__value"
            placeholder="Co chcesz dodać?"
            name={name}
            value={getValue()}
            onChange={handleChange}
          />

          {name !== "schedule" ? (
            <PhotoUpload />
          ) : (
            <RangePicker
              onChange={handleDateChange as any}
              value={selectedRange}
              format="YYYY-MM-DD"
            />
          )}

          <div className="renovation__wrapperButton">
            <Tooltip
              title="Kliknij, aby udostępnić publicznie twój wpis"
              placement="top"
            >
              <Button
                type="primary"
                onClick={() => uploadFunction(false)}
                className="renovation__buttonAdd renovation__buttonAdd--color"
              >
                Udostępnij
              </Button>
            </Tooltip>
            <Tooltip
              title="Kliknij, aby zachować wpis tylko dla siebie"
              placement="top"
            >
              <Button
                type="primary"
                onClick={() => uploadFunction(true)}
                className="renovation__buttonAdd"
              >
                Zachowaj
              </Button>
            </Tooltip>
          </div>
        </div>
      ) : null}

      {sortedTab.length === 0 ? (
        <p className="tabAdmin__text">brak informacji</p>
      ) : (
        sortedTab.map((item, index: number) => (
          <div className="tabAdmin__wrapper" key={index}>
            {item.uid === uid ? (
              <div className="tabAdmin__header">
                <p className="tabAdmin__headerText">
                  {item.isPrivate ? "Wpis prywatny" : "Wpis publiczny"}
                </p>
                <div className="tabAdmin__iconWrapper">
                  <button
                    onClick={() => handleRemove(item)}
                    className="tabAdmin__iconButton"
                  >
                    <img
                      className="tabAdmin__iconImage"
                      src={trashBin}
                      alt="trash"
                    />
                  </button>
                </div>
              </div>
            ) : null}
            <div className="tabAdmin__box">
              {name === "constructionZone" && item.name ? (
                <p className="tabAdmin__implementation">{item.name}</p>
              ) : null}
              <p className="tabAdmin__description">
                {formatterText(item.description || "")}
              </p>
              {item.link ? (
                <img className="tabAdmin__image" src={item.link} alt="name" />
              ) : null}
              {name === "schedule" && item.isPrivate ? (
                <p className="tabAdmin__implementation">
                  czas realizacji {convertDate(item.startTimestamp!)} -{" "}
                  {convertDate(item.endTimestamp!)}
                </p>
              ) : null}
              <p className="tabAdmin__time">
                data utworzenia: {convertDate(item.createdTime || 0)}
              </p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default TabAdmin;
