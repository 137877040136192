/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { useAPI } from "../context";
import { RemontType } from "../context/@types.context";

const useRemont = () => {
  const [loadingRemont, setLoadingRemont] = useState(true);
  const { remontLS, employeeLS, setRemont } = useAPI();
  const [isRemontRemove, setIsRemontRemove] = useState(false);
  const [isUserRemove, setIsUserRemove] = useState(false);

  useEffect(() => {
    if (remontLS && employeeLS) {
      const fetchData = async () => {
        try {
          const docRef = doc(db, "remonts", remontLS);
          const docSnap = await getDoc(docRef);
          const dataRemont = docSnap.data();

          const findMember = dataRemont?.members.find((item: any) => item.employeeKey === employeeLS)

          if (!findMember) {
            setIsUserRemove(true);
            return;
          }

          if (!docSnap.exists()) {
            setIsRemontRemove(true);
            return;
          }

          const docRefUser = doc(db, "users", dataRemont?.createdRemont);
          const docSnapUser = await getDoc(docRefUser);
          if (!docSnapUser.exists()) {
            return;
          }

          const dataAdmin = docSnapUser.data();

          const combinedData: RemontType = {
            ...dataRemont,
            admin: dataAdmin.name
          };

          setRemont(combinedData);

        } catch (error) {
          console.error("Error getting document:", error);
        } finally {
          setLoadingRemont(false);
        }
      };

      fetchData();
    }
  }, [remontLS, employeeLS]);

  return { loadingRemont, setLoadingRemont, isRemontRemove, isUserRemove };
};

export default useRemont;
