/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAPI } from "../../context";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase/firebase";
import useRemont from "../../hooks/useRemont";
import TabUser from "../tabUser";
import dayjs from "dayjs";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { toast, ToastContainer } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Spinner from "../../common/spinner";
import Tab from "../../components/tab";
import Title from "../title";
import { EntryType, DashboardTabsType } from "../../context/@types.context";

const Employee = () => {
  const { loadingRemont, isRemontRemove, isUserRemove } = useRemont();

  const {
    setRemontLS,
    setRemont,
    base64Data,
    setBase64Data,
    selectedFile,
    setEmployeeLS,
    remont,
    remontLS,
    employeeLS,
  } = useAPI();

  const [loading, setLoading] = useState(false);
  const [selectTab, setSelectTab] = useState(remont.constructionZone ?? []);
  const [selectName, setSelectName] = useState("constructionZone");
  const [value, setValue] = useState("");

  const exit = () => {
    setRemontLS("");
    setEmployeeLS("");
  };

  const choiceTab = (tab: EntryType[], name: DashboardTabsType) => {
    setSelectTab(tab);
    setSelectName(name);
  };

  const convertDate = (timestamp: number) => {
    return dayjs.unix(timestamp).format("DD-MM-YYYY");
  };

  const base64ToBlob = (base64: string, mime: string) => {
    const byteChars = atob(base64.split(",")[1]);
    const byteArrays = [];
    for (let offset = 0; offset < byteChars.length; offset += 512) {
      const slice = byteChars.slice(offset, offset + 512);
      byteArrays.push(
        new Uint8Array(slice.split("").map((char) => char.charCodeAt(0)))
      );
    }
    return new Blob(byteArrays, { type: mime });
  };

  const uploadFunction = async () => {
    if (value.length === 0) {
      toast.error("Wprowadz tekst", { autoClose: 3000 });
      return;
    }

    if (remont.completed) {
      toast.info("Eksploatacja / Remont został zakończony", {
        autoClose: 3000,
      });
      return;
    }

    const docRef = doc(db, "remonts", remontLS);
    const docSnap = await getDoc(docRef);
    const dataRemont = docSnap.data();

    const findMember = dataRemont?.members.find(
      (item: any) => item.employeeKey === employeeLS
    );

    if (!findMember) {
      toast.error("Coś poszło nie tak", {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);
    const nameImg = selectedFile ? selectedFile.name : "default.jpg";
    const timestamp = dayjs().unix();
    const uid = localStorage.getItem("employeeID");

    try {
      let fileURL: string | null = null;
      if (base64Data) {
        const mime = "image/jpeg";
        const blob = base64ToBlob(base64Data, mime);
        const storageRef = ref(storage, `images/${nameImg}`);
        const snapshot = await uploadBytes(storageRef, blob);
        fileURL = await getDownloadURL(snapshot.ref);
      }

      const findName = remont.members?.find((item) => item.employeeKey === uid);
      const name = findName?.name ?? "";

      const id = uuidv4();

      const dataObject: EntryType = {
        id: id,
        link: fileURL,
        description: value,
        createdTime: timestamp,
        name: name,
        uid: uid,
      };

      if (!remont.key) {
        throw new Error("Remont key is undefined");
      }

      const docRef = doc(db, "remonts", remont.key);
      await updateDoc(docRef, { constructionZone: arrayUnion(dataObject) });

      setRemont((prevState) => ({
        ...prevState,
        constructionZone: [
          dataObject as EntryType,
          ...(prevState.constructionZone || []),
        ],
      }));

      setSelectTab((prevState) => {
        if (
          Array.isArray(prevState) &&
          prevState.every((item) => "name" in item)
        ) {
          return [dataObject, ...prevState];
        }
        return prevState;
      });

      toast.success("Dodano ogłoszenie", { autoClose: 3000 });
    } catch (error) {
      toast.error("Coś poszło nie tak", { autoClose: 3000 });
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
      setBase64Data(null);
      setValue("");
    }
  };

  useEffect(() => {
    setSelectTab(remont.constructionZone ?? []);
  }, [remont]);

  return (
    <div>
      {isUserRemove || isRemontRemove ? (
        <>
          <div className="employee__buttonWrapper">
            <button className="employee__buttonExit" onClick={exit}>
              Wyloguj
            </button>
          </div>
          <p className="employee__textRemove">
            Coś poszło nie tak...
          </p>
        </>
      ) : (
        <div className="employee">
          <ToastContainer
            theme="colored"
            progressClassName="custom-progress-bar"
          />
          {loadingRemont ? (
            <Spinner />
          ) : (
            <>
              <Tab {...{ selectName, choiceTab }} />
              <div className="employee__buttonWrapper">
                <button className="employee__buttonExit" onClick={exit}>
                  Wyloguj
                </button>
              </div>
              <Title />
              <p className="employee__text">prowadzący: {remont.admin}</p>
              <p className="employee__text">opis: {remont.description}</p>
              <p className="employee__text">
                data: {convertDate(remont.createdTime ?? 0)}
              </p>
              <TabUser
                tab={selectTab}
                setTab={setSelectTab}
                name={selectName}
                uploadFunction={uploadFunction}
                value={value}
                setValue={setValue}
                loading={loading}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Employee;
