import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ResetPassword from "../resetPassword";
import ActionHandler from "../actionHandler";
import Home from "../../pages/home";
import Register from "../../pages/register";
import Login from "../../pages/login";
import Creator from "../../pages/creator";
import Repairs from "../../pages/repairs";
import JoinTheRenovation from "../../pages/joinTheRenovation";
import Navigation from "../navigation";
import ModalCookies from "../../modals/modalCookies";

const App = () => {

  return (
    <BrowserRouter>
      <Navigation />
      <ModalCookies />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/auth" element={<ActionHandler />}/>
        <Route path="/register" element={<Register />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/creator" element={<Creator />}/>
        <Route path="/repairs" element={<Repairs />}/>
        <Route path="/joinTheRenovation" element={<JoinTheRenovation />}/>
        <Route path="/passwordReminder" element={<ResetPassword />}/>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
