import { useState } from "react";
import { useAPI } from "../../context";
import { arrayUnion, doc, runTransaction } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../common/spinner";
import { EmployeeKeysType, MembersType } from "../../context/@types.context";

const JoinTheRenovation = () => {
  const { setRemontLS, setEmployeeLS } = useAPI();

  const [keyRemont, setKeyRemont] = useState("");
  const [keyEmployee, setKeyEmployee] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [fullName, setFullName] = useState("");
  const [specialization, setSpecialization] = useState("");

  const handleInputChangeKeyRemont = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setKeyRemont(value);
  };

  const handleInputChangeKeyEmployee = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setKeyEmployee(value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const formattedValue = value
      .split(" ")
      .map(
        (namePart) => namePart.charAt(0).toUpperCase() + namePart.slice(1)
      )
      .join(" ");

    setFullName(formattedValue);
  };

  const handleSpecializationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSpecialization(e.target.value);
  };

  const join = async () => {

    setIsLoading(true);

    const remontKey = keyRemont;
    const employeeKey = keyEmployee;

    try {
      const remontDocRef = doc(db, "remonts", remontKey);

      await runTransaction(db, async (transaction) => {
        const docSnap = await transaction.get(remontDocRef);

        if (!docSnap.exists()) {
          toast.error(
            "Klucz jednostki lub klucz pracowniczy jest nie poprawny",
            {
              autoClose: 3000,
            }
          );
          return;
        }

        const data = docSnap.data();

        const findEmployeeKey = data.employeeKeys.find(
          (e: EmployeeKeysType) => e.key === employeeKey
        );

        if (!findEmployeeKey) {
          toast.error(
            "Klucz jednostki lub klucz pracowniczy jest nie poprawny",
            {
              autoClose: 3000,
            }
          );
          return;
        }

        const findMember = data.members.find(
          (e: MembersType) => e.employeeKey === employeeKey
        );

        if (findMember) {
          setRemontLS(remontKey);
          setEmployeeLS(employeeKey);
          navigate("/");
          return;
        }

        const obj = {
          name: fullName,
          specialization: specialization,
          employeeKey: employeeKey,
        };
        transaction.update(remontDocRef, {
          members: arrayUnion(obj),
        });

        const updatedEmployeeKeys = data.employeeKeys.map((e: EmployeeKeysType) =>
          e.key === employeeKey ? { ...e, isActive: true } : e
        );

        transaction.update(remontDocRef, {
          employeeKeys: updatedEmployeeKeys,
        });

        setRemontLS(remontKey);
        setEmployeeLS(employeeKey);
        navigate("/");
      });
    } catch (error) {
      console.error("Error updating member: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="joinTheRenovation">
      <ToastContainer theme="colored" progressClassName="custom-progress-bar" />
      <h1 className="joinTheRenovation__title">Dołącz</h1>
      <div className="joinTheRenovation__wrapper">
        {isLoading ? <Spinner /> : null}
        <div style={{ marginTop: "10px" }}>
          <input
            maxLength={50}
            className="joinTheRenovation__value"
            value={fullName}
            placeholder="Imię i nazwisko"
            onChange={(e) => handleNameChange(e)}
          />
        </div>
        <input
          maxLength={50}
          className="joinTheRenovation__value"
          value={specialization}
          placeholder="Specjalizacja"
          onChange={(e) => handleSpecializationChange(e)}
        />

        <input
          maxLength={50}
          className="joinTheRenovation__value"
          placeholder="Klucz do jednostki"
          value={keyRemont}
          onChange={(event) => handleInputChangeKeyRemont(event)}
        />

        <input
          maxLength={50}
          className="joinTheRenovation__value"
          placeholder="Klucz pracowniczy"
          value={keyEmployee}
          onChange={(event) => handleInputChangeKeyEmployee(event)}
        />

        <button onClick={join} className="joinTheRenovation__button">
          Dołącz
        </button>
        <Link to="/" className="joinTheRenovation__link">
          Powrót
        </Link>
      </div>
    </div>
  );
};

export default JoinTheRenovation;
