import { useEffect, useState } from "react";
import { auth, db } from "../../firebase/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { doc, setDoc } from "firebase/firestore";
import Spinner from "../../common/spinner";
import Modal from "react-modal";
import { customStyles } from "../../utils/customStyles";
import { Link, useNavigate } from "react-router-dom";
import { useAPI } from "../../context";

const Register = () => {

  const { cookies } = useAPI();

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const [succesful, setSuccesful] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [resendEmail, setResendEmail] = useState("");
  const [resendPassword, setResendPassword] = useState("");

  const navigate = useNavigate();

  const { name, email, password, confirmPassword } = user;

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return `Hasło musi mieć co najmniej ${minLength} znaków.`;
    }
    if (!hasUpperCase) {
      return "Hasło musi zawierać co najmniej jedną wielką literę.";
    }
    if (!hasLowerCase) {
      return "Hasło musi zawierać co najmniej jedną małą literę.";
    }
    if (!hasNumber) {
      return "Hasło musi zawierać co najmniej jedną cyfrę.";
    }
    if (!hasSpecialChar) {
      return "Hasło musi zawierać co najmniej jeden znak specjalny.";
    }

    return null;
  };

  const register = async () => {
    if (
      name.length === 0 ||
      email.length === 0 ||
      password.length === 0 ||
      confirmPassword.length === 0
    ) {
      toast.error("Uzupełnij wszystkie dane", {
        autoClose: 3000,
      });
      return;
    }

    const passwordError = validatePassword(password);
    if (passwordError) {
      toast.error(passwordError, {
        autoClose: 3000,
      });
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Hasła nie są zgodne", {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);
    try {
      const newUser = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const userDocRef = doc(db, "users", newUser.user.uid);

      await setDoc(userDocRef, {
        name: name,
        email: email,
      });

      await sendEmailVerification(newUser.user);

      setSuccesful(true);
    } catch (error) {
      console.log(error);
      toast.error("Wystąpił błąd podczas rejestracji. Spróbuj ponownie.", {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
      setUser({ name: "", email: "", password: "", confirmPassword: "" });
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    setUser({ ...user, [evt.target.name]: value });
  };

  const handleResendVerification = async () => {
    setLoadingLink(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        resendEmail,
        resendPassword
      );
      const userToVerify = userCredential.user;
  
      if (userToVerify.emailVerified) {
        toast.info("Twoje konto zostało już zweryfikowane.", {
          autoClose: 3000,
        });
      } else {
        await sendEmailVerification(userToVerify);
        toast.success("Link aktywacyjny został ponownie wysłany.", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Nie udało się wysłać linku aktywacyjnego. Upewnij się, że podałeś prawidłowe dane.",
        {
          autoClose: 3000,
        }
      );
    } finally {
      setLoadingLink(false);
      setModalOpen(false);
      setResendEmail("");
      setResendPassword("");
    }
  };

  useEffect(() => {
    if (cookies.token) {
      navigate("/");
    }
  }, [cookies.token, navigate]);
  
  return (
    <div className="register">
      <ToastContainer theme="colored" progressClassName="custom-progress-bar" />
      {succesful ? (
        <h1 className="register__title">
          Rejestracja przebiegła pomyślnie. Sprawdź swoją skrzynkę e-mail, aby
          potwierdzić rejestrację.
        </h1>
      ) : (
        <h1 className="register__title">Rejestracja</h1>
      )}
      <div className="register__wrapper">
        {loading ? <Spinner /> : null}
        {!loading ? (
          <form
            className="register__form"
            onSubmit={(event) => event.preventDefault()}
          >
            <input
              onChange={(e) => handleChange(e)}
              className="register__item"
              placeholder="Imię i nazwisko"
              type="text"
              value={name}
              name="name"
              maxLength={50}
            />
            <input
              onChange={(e) => handleChange(e)}
              className="register__item"
              placeholder="Email"
              type="text"
              value={email}
              name="email"
              maxLength={50}
            />
            <input
              onChange={(e) => handleChange(e)}
              className="register__item"
              placeholder="Hasło"
              type="password"
              value={password}
              name="password"
              maxLength={50}
            />
            <input
              onChange={(e) => handleChange(e)}
              className="register__item"
              placeholder="Powtórz hasło"
              type="password"
              value={confirmPassword}
              name="confirmPassword"
              maxLength={50}
            />

            <button
              onClick={register}
              className="register__item register__item--submit"
            >
              Zarejestruj się
            </button>
            <button
              onClick={() => setModalOpen(true)}
              className="register__resend-link"
            >
              Nie dostałeś linku aktywacyjnego? Kliknij tutaj
            </button>
          </form>
        ) : null}
        {!loading ? (
          <Link className="register__link" to="/login">
            Zaloguj się
          </Link>
        ) : null}
      </div>
      <Modal
        isOpen={modalOpen}
        style={customStyles}
        onRequestClose={() => setModalOpen(false)}
        contentLabel="Resend Verification Email"
      >
        <div className="register__modalLinkActive">
          {loadingLink ? <Spinner /> : null}
          <h2 className="register__description">
            Wyślij ponownie link aktywacyjny
          </h2>
          <input
            maxLength={50}
            className="register__item"
            type="email"
            value={resendEmail}
            onChange={(e) => setResendEmail(e.target.value)}
            placeholder="Wpisz swój e-mail"
          />
          <input
            maxLength={50}
            className="register__item"
            type="password"
            value={resendPassword}
            onChange={(e) => setResendPassword(e.target.value)}
            placeholder="Wpisz swoje hasło"
          />
          <button
            className="register__item register__item--submit"
            onClick={handleResendVerification}
          >
            Wyślij ponownie
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Register;
