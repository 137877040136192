import backgroundMain from "../assets/backgroundMain.webp";
import home from "../assets/home.png";
import user from "../assets/user.png";
import logo from "../assets/logo.png";
import setting from "../assets/setting.png";
import avatar from "../assets/avatar.png";
import write from "../assets/write.png";
import chat from "../assets/chat.png";
import arrow from "../assets/arrow.png";
import trash_bin from "../assets/trash_bin.png";
import add from "../assets/add.png";
import verified from "../assets/verified.png";

const images = {
  backgroundMain,
  home,
  user,
  logo,
  setting,
  avatar,
  write,
  chat,
  arrow,
  trash_bin,
  add,
  verified,
};

export default images;
