import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB3Z2XIzuE_BeXbEBf-40SgufTCOaoDKsA",
  authDomain: "vesselhost-99f57.firebaseapp.com",
  projectId: "vesselhost-99f57",
  storageBucket: "vesselhost-99f57.appspot.com",
  messagingSenderId: "178298187590",
  appId: "1:178298187590:web:8a9a77d048de24d12fbf47",
  measurementId: "G-533VDS8DYK"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);