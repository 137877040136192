import { ReactNode } from "react";
import Overlap from "../../common/overlap";

type SectionType = {
  title: string;
  children: ReactNode;
  dispatch: () => void;
};

const Section = ({ title, dispatch, children }: SectionType) => {
  return (
    <div className="section">
      <Overlap title={title} dispatch={dispatch} />
      <div className="section__wrapper">{children}</div>
    </div>
  );
};

export default Section;
