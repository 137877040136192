import { SectionType } from "../../context/@types.context";
import Section from "../section";

const PrivacyPolicy = ({ dispatch, title }: SectionType) => {
  return (
    <Section dispatch={dispatch} title={title || ""}>
      <div className="privacyPolicy">
        <p className="privacyPolicy__text privacyPolicy__text--title">
          1. Postanowienia ogólne
        </p>
        <p className="privacyPolicy__text">
          Niniejsza Polityka Prywatności określa zasady przetwarzania danych
          osobowych Użytkowników systemu CRM vesselhost.com przez [
          sp.z.o.o z siedzibą w Gdańsku] (zwaną dalej "Administratorem").
        </p>
        <p className="privacyPolicy__text">
          Administrator dokłada wszelkich starań, aby zapewnić ochronę danych
          osobowych zgodnie z obowiązującymi przepisami prawa, w szczególności z
          Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
          27 kwietnia 2016 r. (RODO).
        </p>
        <p className="privacyPolicy__text privacyPolicy__text--title">
          2. Zakres zbieranych danych
        </p>
        <p className="privacyPolicy__text">
          Administrator przetwarza dane osobowe Użytkowników, które są niezbędne
          do realizacji celów wynikających z funkcjonalności Systemu, takie jak:
        </p>
        <p className="privacyPolicy__text">Imię i nazwisko</p>
        <p className="privacyPolicy__text">Adres e-mail</p>
        <p className="privacyPolicy__text">
          Dane dotyczące działalności gospodarczej (np. NIP, REGON)
        </p>
        <p className="privacyPolicy__text">
          Historia kontaktów z Użytkownikiem
        </p>
        <p className="privacyPolicy__text">
          Inne dane związane z relacjami biznesowymi.
        </p>
        <p className="privacyPolicy__text privacyPolicy__text--title">
          3. Cele przetwarzania danych
        </p>
        <p className="privacyPolicy__text">
          Dane osobowe przetwarzane są w celu:
        </p>
        <p className="privacyPolicy__text">
          Utrzymania relacji biznesowych z Użytkownikiem,
        </p>
        <p className="privacyPolicy__text">
          Prowadzenia korespondencji elektronicznej,
        </p>
        <p className="privacyPolicy__text">
          Świadczenia usług oferowanych przez System,
        </p>
        <p className="privacyPolicy__text">
          Analizy i raportowania w zakresie relacji biznesowych,
        </p>
        <p className="privacyPolicy__text">
          Zabezpieczenia interesów Administratora.
        </p>
        <p className="privacyPolicy__text privacyPolicy__text--title">
          4. Podstawa prawna przetwarzania
        </p>
        <p className="privacyPolicy__text">
          Dane osobowe przetwarzane są na podstawie:
        </p>
        <p className="privacyPolicy__text">
          Art. 6 ust. 1 lit. b) RODO – niezbędność przetwarzania danych do
          wykonania umowy,
        </p>
        <p className="privacyPolicy__text">
          Art. 6 ust. 1 lit. f) RODO – prawnie uzasadniony interes
        </p>
        <p className="privacyPolicy__text">
          Art. 6 ust. 1 lit. a) RODO – zgoda Użytkownika (jeśli jest wymagana)
        </p>
        <p className="privacyPolicy__text privacyPolicy__text--title">
          5.Przechowywanie danych
        </p>
        <p className="privacyPolicy__text">
          Dane osobowe Użytkowników będą przechowywane przez okres niezbędny do
          realizacji celów przetwarzania, a po ich zakończeniu przez okres
          wymagany przepisami prawa lub do czasu przedawnienia ewentualnych
          roszczeń.
        </p>
        <p className="privacyPolicy__text privacyPolicy__text--title">
          6. Prawa Użytkowników
        </p>
        <p className="privacyPolicy__text">
          Dostępu do swoich danych osobowych,
        </p>
        <p className="privacyPolicy__text">
          Sprostowania danych, jeśli są nieprawidłowe lub niekompletne,
        </p>
        <p className="privacyPolicy__text">
          Usunięcia danych ("prawo do bycia zapomnianym"),
        </p>

        <p className="privacyPolicy__text">
          Ograniczenia przetwarzania danych,
        </p>
        <p className="privacyPolicy__text">Przenoszenia danych,</p>
        <p className="privacyPolicy__text">
          Sprzeciwu wobec przetwarzania danych na podstawie prawnie
          uzasadnionego interesu,
        </p>
        <p className="privacyPolicy__text">
          Wniesienia skargi do organu nadzorczego (Prezesa Urzędu Ochrony Danych
          Osobowych).
        </p>
        <p className="privacyPolicy__text privacyPolicy__text--title">
          7. Odbiorcy danych
        </p>

        <p className="privacyPolicy__text">
          Dane osobowe Użytkowników mogą być udostępniane podmiotom trzecim
          wyłącznie w zakresie niezbędnym do realizacji celów przetwarzania, w
          tym: Podmiotom świadczącym usługi IT na rzecz Administratora,
          Partnerom biznesowym Administratora (w zakresie niezbędnym do
          realizacji współpracy),
        </p>
        <p className="privacyPolicy__text">
          Organom administracji publicznej, jeśli wymaga tego prawo.
        </p>
        <p className="privacyPolicy__text privacyPolicy__text--title">
          8. Przekazywanie danych poza EOG
        </p>
        <p className="privacyPolicy__text">
          Dane osobowe Użytkowników mogą być przekazywane do państw spoza
          Europejskiego Obszaru Gospodarczego (EOG) tylko wtedy, gdy jest to
          konieczne, a dany kraj zapewnia odpowiedni poziom ochrony danych
          osobowych zgodnie z decyzją Komisji Europejskiej lub przy zastosowaniu
          odpowiednich zabezpieczeń.
        </p>
        <p className="privacyPolicy__text privacyPolicy__text--title">
          9. Środki ochrony danych
        </p>
        <p className="privacyPolicy__text">
          Administrator stosuje odpowiednie środki techniczne i organizacyjne,
          aby zapewnić ochronę przetwarzanych danych osobowych, w tym
          zabezpieczenia przed ich nieautoryzowanym dostępem, modyfikacją,
          usunięciem czy ujawnieniem.
        </p>
        <p className="privacyPolicy__text privacyPolicy__text--title">
          10. Zmiany w Polityce Prywatności
        </p>
        <p className="privacyPolicy__text">
          Administrator zastrzega sobie prawo do wprowadzania zmian w niniejszej
          Polityce Prywatności w dowolnym momencie. Zmiany te wchodzą w życie z
          dniem ich opublikowania w Systemie.
        </p>
        <p className="privacyPolicy__text privacyPolicy__text--title">
          11. Kontakt
        </p>
        <p className="privacyPolicy__text">
          W sprawach dotyczących przetwarzania danych osobowych Użytkownicy mogą
          kontaktować się z Administratorem za pośrednictwem e-maila:
          lesniakprogramowanie@gmail.com
        </p>
      </div>
    </Section>
  );
};

export default PrivacyPolicy;
