/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { LOCAL_STORAGE } from "../enums/localStorage";

const useLocalstorage = () => {
  
    const [remontLS, setRemontLS] = useState(window.localStorage.getItem(LOCAL_STORAGE.REMONT_ID) || '')
    const [employeeLS, setEmployeeLS] = useState(window.localStorage.getItem(LOCAL_STORAGE.EMPLOYEE_ID) || '')

    useEffect(() => {
        window.localStorage.setItem(LOCAL_STORAGE.REMONT_ID, remontLS)
        window.localStorage.setItem(LOCAL_STORAGE.EMPLOYEE_ID, employeeLS)
    })
  
    return {  setRemontLS, remontLS, employeeLS, setEmployeeLS }

}

export default useLocalstorage;