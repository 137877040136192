import images from "../../imagesList";
import { useAPI } from "../../context";
import ModalAboutUs from "../../modals/modalAboutUs";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {

  const { home, logo, user } = images;
  const { loadingProfile, cookies, isShow, setIsShow, dataUser, employeeLS, remont } = useAPI();

  const [isOpenModal, setisOpenModal] = useState(false);

  const location = useLocation()

  const logged = () => {
    setIsShow(false)
  }

  const closeModal = () => { 
    setisOpenModal(false)
  }

  const openModal = () => { 
    setisOpenModal(true)
  }

  const findUser = remont?.members?.find(item => item.employeeKey === employeeLS)

  return (
    <header className="navigation">
      {isShow ? 
        <button className="navigation__buttonBack" onClick={() => setIsShow(false)}>
            &lt;
        </button> : null}
      <ModalAboutUs {...{isOpenModal, closeModal}}/>
      <img className="navigation__logo" src={logo} alt="logo" />
      {cookies.token ? <p className="navigation__name">{dataUser.name}</p> : null}
      {findUser ? <p className="navigation__name">{findUser.name}</p> : null}
      <ul className="navigation__list">    
        {!loadingProfile && !cookies.token ? (
          <>
          <li onClick={openModal} className="navigation__group">
            <span className="navigation__element">O nas</span>
          </li>
          </>
        ) : (
          <>
            <li className="navigation__item navigation__item--cursor">
              <Link to="/creator" onClick={() => setIsShow(false)}>
                Eksploatacja / Remont
              </Link>
            </li>
            <li className="navigation__item navigation__item--cursor">
              <Link to="/repairs" onClick={() => setIsShow(false)}>
                Lista
              </Link>
            </li>
            <li className="navigation__item navigation__item--profile">
              <Link to="/" onClick={() => setIsShow(false)}>
                <img className="navigation__icon navigation__icon--profile" src={user} alt="user" />
              </Link>
            </li>
          </>
        )}
        <li>
          {employeeLS !== "" ? null : location.pathname === "/" && !cookies.token ?<Link className="navigation__item navigation__item--cursor" to="/login" onClick={logged}>
            Zaloguj się
          </Link> : null}
        </li>
        <li>
          <Link className="navigation__item" to="/" onClick={() => setIsShow(false)}>
            <img className="navigation__icon" src={home} alt="home" />
          </Link>
        </li>
      </ul>
    </header>
  );
};

export default Navigation;
