import { useAPI } from "../../context";

const Title = () => {

    const { remont } = useAPI();

    return (
        <p className="title">{remont.name}</p>
    )
}

export default Title;