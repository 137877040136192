import { useLocation } from "react-router-dom";
import EmailVerification from "../../pages/emailVerification";
import ResetPassword from "../../pages/resetPassword";

const ActionHandler = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get("mode");

  switch (mode) {
    case "resetPassword":
      return <ResetPassword />;
    case "verifyEmail":
      return <EmailVerification />;
    default:
      return <div>Nieprawidłowa akcja</div>;
  }
};

export default ActionHandler;