import { createContext, Dispatch, ReactNode, SetStateAction } from "react";

export interface ListType {
  name: string;
  description: string;
  key: string;
  ids: string;
  completed: boolean;
  employeeKeys?: EmployeeKeysType[];
  createdRemont: string;
  createdTime: number;
  constructionZone: EntryType[];
  noticeBoard: EntryType[];
  schedule: EntryType[];
  specifications: EntryType[];
  members: MembersType[];
}

export interface EntryType {
  id?: string;
  description?: string;
  createdTime?: number;
  startTimestamp?: number;
  endTimestamp?: number;
  isPrivate?: boolean;
  link?: null | string;
  name?: string;
  uid?: string | null;
}

export interface SectionType {
  title?: string;
  dispatch: () => void;
}

export type DashboardTabsType = 'constructionZone' | 'noticeBoard' | 'schedule' | 'specifications';

export interface EmployeeKeysType {
  isActive: boolean;
  key: string;
}

export interface MembersType {
  name: string;
  employeeKey: string;
  specialization: string;
}

export interface RemontType {
  name?: string;
  description?: string;
  key?: string;
  completed?: boolean;
  constructionZone?: EntryType[];
  noticeBoard?: EntryType[];
  schedule?: EntryType[];
  specifications?: EntryType[];
  employeeKeys?: EmployeeKeysType[];
  members?: MembersType[];
  admin?: string;
  createdTime?: number;
}

export interface DataUserType {
  name: string;
  email: string;
  password?: string;
  id: string;
}

export interface APIContextProviderProps {
  children: ReactNode;
}

type APIContextTypes = {
  isHamburgerMenu: boolean;
  setIsHamburgerMenu: Dispatch<SetStateAction<boolean>>;
  list: ListType[];
  setList: Dispatch<SetStateAction<ListType[]>>;
  remont: RemontType;
  setRemont: Dispatch<SetStateAction<RemontType>>;
  cookies: { [key: string]: string };
  setCookie: (name: string, value: string, options?: any) => void;
  removeCookie: (name: string, options?: any) => void;
  dataUser: DataUserType;
  setDataUser: Dispatch<SetStateAction<DataUserType>>;
  loadingProfile: boolean;
  setLoadingProfile: Dispatch<SetStateAction<boolean>>;
  remontLS: string;
  setRemontLS: Dispatch<SetStateAction<string>>;
  employeeLS: string;
  setEmployeeLS: Dispatch<SetStateAction<string>>;
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  selectedFile: File | null;
  setSelectedFile: Dispatch<SetStateAction<File | null>>;
  base64Data: string | null;
  setBase64Data: Dispatch<SetStateAction<string | null>>;
};

const getDefaultRemont = (): RemontType => ({
  name: "",
  description: "",
  key: "",
  completed: false,
  constructionZone: [],
  noticeBoard: [],
  schedule: [],
  specifications: [],
  employeeKeys: [],
  members: [],
  admin: "",
  createdTime: 0
});

const getDefaultDataUser = (): DataUserType => ({
  name: "",
  email: "",
  password: "",
  id: ""
});

const defaultAPIContext: APIContextTypes = {
  isHamburgerMenu: false,
  setIsHamburgerMenu: () => {},
  list: [],
  setList: () => {},
  remont: getDefaultRemont(),
  setRemont: () => {},
  cookies: {},
  setCookie: () => {},
  removeCookie: () => {},
  dataUser: getDefaultDataUser(),
  setDataUser: () => {},
  loadingProfile: false,
  setLoadingProfile: () => {},
  remontLS: "",
  setRemontLS: () => {},
  employeeLS: "",
  setEmployeeLS: () => {},
  isShow: false,
  setIsShow: () => {},
  selectedFile: null,
  setSelectedFile: () => {},
  base64Data: null,
  setBase64Data: () => {}
};

export const APIContext = createContext<APIContextTypes>(defaultAPIContext);