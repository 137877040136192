/* eslint-disable react-hooks/exhaustive-deps */
import { useAPI } from "../../context";
import images from "../../imagesList";
import { useState, useEffect } from "react";
import useProfile from "../../hooks/useProfile";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { ToastContainer } from "react-toastify";
import ModalEditRepair from "../../modals/modalEditRepair";
import Renovation from "../../components/renovation";
import Spinner from "../../common/spinner";
import Error from "../../components/error";
import { ListType, RemontType } from "../../context/@types.context";

const Repairs = () => {
  const { setting, verified } = images;

  const { list, setRemont, cookies, setList, loadingProfile, isShow, setIsShow } = useAPI();
  useProfile();

  const [num, setNum] = useState<number | null>(null);
  const [controll, setControll] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [indexRemont, setIndexRemont] = useState<number>(0);
  const [isLoadingRemonts, setIsLoadingRemonts] = useState<boolean>(false);
  const [error, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (cookies.token) {
      const fetchData = async () => {
        const uid = localStorage.getItem("uid");

        if (!uid) {
          return;
        }

        try {
          const remontsCollectionRef = collection(db, "remonts");
          const q = query(
            remontsCollectionRef,
            where("createdRemont", "==", uid)
          );
          const querySnapshot = await getDocs(q);

          const remontsList: ListType[] = querySnapshot.docs.map((doc) => {
            const data = doc.data() as Partial<ListType>;
      
            return {
              name: data.name ?? '',
              description: data.description ?? '',
              key: data.key ?? '',
              ids: data.ids ?? '',
              completed: data.completed ?? false,
              employeeKeys: data.employeeKeys ?? [],
              createdRemont: data.createdRemont ?? '',
              createdTime: data.createdTime ?? 0,
              constructionZone: data.constructionZone ?? [],
              noticeBoard: data.noticeBoard ?? [],
              schedule: data.schedule ?? [],
              specifications: data.specifications ?? [],
              members: data.members ?? []
            };
          });
      
          setList(remontsList);

        } catch (error) {
          console.error("Error fetching documents: ", error);
        } finally {
          setIsLoadingRemonts(true);
        }
      };

      fetchData();
    }
  }, [cookies.token]);

  const showDescription = (index: number) => {
    setNum((prevNum) => (prevNum = index));
    if (index === num) {
      setControll(!controll);
    } else {
      setControll(false);
    }
  };

  const editRemont = (item: RemontType, index: number) => {
    setRemont(item);
    setIsModal(true);
    setIndexRemont(index);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const showRemont = async (item: RemontType) => {
    setIsShow(true);
    setRemont(item);
  };

  return (
    <div className="repairs">
      <ToastContainer theme="colored" progressClassName="custom-progress-bar" />
      {cookies.token ? (
        isLoadingRemonts && loadingProfile ? (
          <>
            <ModalEditRepair
              {...{ indexRemont, isModal, closeModal, error, setIsError }}
            />
            {!isShow ? (
              list.length === 0 ? (
                <p className="repairs__text">Nie posiadasz żadnych remontów</p>
              ) : (
                list.map((item, index) => (
                  <div className="repairs__container" key={index}>
                    {item.completed ? (
                      <img
                        className="repairs__iconApproved"
                        src={verified}
                        alt="verified"
                      />
                    ) : null}
                    <div
                      className={
                        num === index && !controll
                          ? "repairs__wrapperActive show"
                          : "repairs__wrapper"
                      }
                    >
                      <div className="repairs__box">
                        <button
                          className="repairs__buttonExpand"
                          onClick={() => showDescription(index)}
                        >
                          &gt;
                        </button>
                        <button
                          className="repairs__buttonShow"
                          onClick={() => showRemont(item)}
                        >
                          {item.name}
                        </button>
                        <button
                          className="repairs__buttonEdit"
                          onClick={() => editRemont(item, index)}
                        >
                          <img
                            className="repairs__icon"
                            src={setting}
                            alt="setting"
                          />
                        </button>
                      </div>
                      <p className="repairs__description">{item.description}</p>
                    </div>
                  </div>
                ))
              )
            ) : (
              <Renovation />
            )}
          </>
        ) : error ? (
          <Error />
        ) : (
          <Spinner />
        )
      ) : (
        <div className="repairs__wrap">
          <p className="repairs__textTwo">Nie posiadasz żadnych remontów</p>
        </div>
      )}
    </div>
  );
};

export default Repairs;
