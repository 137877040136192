/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { generateKey } from "../utils/generateKey";

const useKey = (loading: boolean) => {
  const [loadingKey, setLoadingKey] = useState(true);
  const [key, setKey] = useState<string[]>([]);

  const checkKeyExists = async (key: string) => {
    try {
      const remontsCollectionRef = collection(db, "remonts");
      const q = query(remontsCollectionRef, where("key", "==", key));
      const querySnapshot = await getDocs(q);

      const keyExists = !querySnapshot.empty;

      if (keyExists) {
        const newKey = generateKey();
        setKey(newKey.split(""));
        checkKeyExists(newKey);
      } else {
        setLoadingKey(false);
      }
    } catch (error) {
      console.error("Error checking key existence: ", error);
    }
  };

  useEffect(() => {
    if (!loading) {
      const newKey = generateKey();
      setKey(newKey.split(""));
      checkKeyExists(newKey);
    }
  }, [loading]);

  return { loadingKey, key, setLoadingKey, setKey, generateKey };
};

export default useKey;
